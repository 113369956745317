<template>
  <div class="container">
    <component :is="container">
      <component :is="currentComponent" />
      <SideMenu v-if="DESKTOP" @resetScroll="resetScroll" />
      <Basement v-else/>
    </component>
  </div>
</template>

<script>
import { 
  Slider, 
  News, 
  Post, 
  AboutUs, 
  OurTeam, 
  WhyUs, 
  Services, 
  ContactUs, 
  SideMenu,
  Basement 
} from '@/components';

export default {
  name: 'Home',
  components: {
    Slider, 
    News, 
    Post,
    AboutUs,
    OurTeam,
    WhyUs,
    Services,
    ContactUs,
    SideMenu, 
    Basement
  },
  computed: {
    currentComponent() {
      return this.$store.getters['CURRENT_COMPONENT'].component;
    },
    container() {
      return this.DESKTOP ? 'perfect-scrollbar' : 'div';
    },
  },
  methods: {
    resetScroll() {
      this.$refs.scrollbar.$el.scrollTop = 0;
    }
  }
}
</script>
